<template>
    <div
        class="tw-group tw-relative tw-bg-white tw-rounded-lg tw-overflow-hidden tw-h-full hover:tw-shadow-lg tw-transition tw-duration-300"
        @click="processClick"
    >
        <v-btn
            icon
            class="tw-absolute tw-shadow-none tw-bg-gray-100 tw-bg-opacity-75 tw-top-0 tw-right-0 tw-m-4 tw-z-10"
            @click.prevent="handleSave"
            v-if="profile"
        >
            <k-icon name="heart" :class="{ 'tw-text-black': isSaved, 'tw-text-gray-800': !isSaved }" size="16" />
        </v-btn>
        <a :href="post.link" target="_blank" rel="noopener noreferrer" class="tw-no-underline">
            <div v-if="post.image" class="tw-aspect-ratio-19:10 tw-w-full">
                <img :src="post.image" @error="$event.target.src = 'https://via.placeholder.com/190x100?text=+'" class="tw-object-cover tw-object-center" />
            </div>
            <div class="tw-relative tw-z-10 tw-bg-red-500 group-hover:tw--translate-y-4 tw-transform tw-duration-200 tw-ease-out">
                <div class="tw-py-6 tw-px-4 tw-bg-white">
                    <p class="tw-w-52 tw-text-black tw-h-10 tw-line-clamp-2">{{ post.title }}</p>

                    <div class="tw-flex tw-items-center tw-mt-6">
                        <img
                            v-if="post.icon"
                            :src="post.icon"
                            :alt="post.title"
                            class="tw-w-5 tw-h-5"
                            @error="$event.target.src = '/web/home/default_favicon.png'"
                        />
                        <p class="tw-ml-2 tw-text-gray-800 tw-line-clamp-1">{{ post.siteName }}</p>
                    </div>
                </div>
                <div class="tw-absolute tw-bottom-0 tw-z-10 tw-w-full tw-px-4 tw-translate-y-1 tw-transform">
                    <p
                        class=" tw-opacity-0 group-hover:tw-opacity-100 tw-text-xs tw-text-gray-800 tw-inline-flex tw-justify-between tw-items-center tw-w-full tw-leading-none tw-transition tw-duration-200"
                    >
                        <span>{{ post.timestamp | timeDiff }}</span>
                        <span class="tw-inline-flex tw-items-center">
                            <k-icon name="visible" size="12" class="tw-mr-1 tw-mt-px" />
                            <span class="tw-mb-px">{{ post.timesClicked || 0 }}</span>
                        </span>
                    </p>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        post: {
            type: Object
        }
    },
    computed: {
        profile() {
            return this.$store.state.USER.profile;
        },
        isSaved() {
            if (!this.profile || !this.post) return false;
            return (this.profile.savedPosts || []).indexOf(this.post.id) !== -1;
        }
    },
    methods: {
        processClick() {
            this.$store.dispatch("POSTS/postClicked", this.post.id);
            this.$emit("gaEvent");
        },
        handleSave() {
            if (this.isSaved) {
                this.$store.dispatch("USER/UPDATE_ENTITY", {
                    type: "remove",
                    entityName: "savedPosts",
                    entityId: this.post.id
                });
            } else {
                this.$store.dispatch("USER/UPDATE_ENTITY", {
                    type: "save",
                    entityName: "savedPosts",
                    entityId: this.post.id
                });
            }
        }
    }
};
</script>
